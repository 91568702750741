.cart-badge .MuiBadge-root {
  color: #000 !important;
}
.MuiBadge-badge {
  background-color: red;
}
.card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}

/* .card::before {
  content: "";
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  background: #4caf50;
  transform: skewY(345deg);
  transition: 0.5s;
} */

/* .card:hover {
  top: -70%;
} */

/* .card::after {
  content: " KARDI ";
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 600;
  font-size: 6em;
  color: rgba(0, 0, 0, 0.1);
  text-align: center;
} */

.card .imgBox {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/*
  .card .imgBox img {
      max-width: 100%;
  
      transition: .5s;
  }
  
  .card:hover .imgBox img {
      max-width: 50%;
  
  }
  */
.card .contentBox {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.card .contentBox h3 {
  font-size: 18px;
  color: #212529;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.card .contentBox .price {
  font-size: 24px;
  color: #212529;
  font-weight: 700;
  letter-spacing: 1px;
}

.card .contentBox .buy {
  position: relative;
  top: 0;
  opacity: 1;
  display: flex;
}

/* .card:hover .contentBox .buy {
  top: 0;
  opacity: 1;
} */

.mouse {
  height: 300px;
  width: auto;
}

.button-cart {
  border: 1px solid #053c48;
  color: #fff;
  height: 35px;
  line-height: 31px;
  margin-top: 0;
  padding: 0;
  text-align: center;
  width: 35px;
  background-color: #053c48;
  font-size: 14px;
  cursor: pointer;
}
/* .jtv-extra-link a:hover {
  background: #4caf50 none repeat scroll 0 0;
  border-color: #4caf50;
  color: #fff;
} */
.jtv-extra-link a {
  border: 1px solid #ccc;
  color: #666;
  float: left;
  height: 35px;
  line-height: 34px;
  margin-right: 6px;
  margin-top: 0;
  width: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  color: #053c48;
}
